var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-special-services-qr-codes",
      "title": "Thông tin QR Code",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.showHandle,
      "hide": _vm.hideHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])], 1)];
      }
    }])
  }, [_c('div', [_vm.dataSpecialItem ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("Họ và tên người mua:")]), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.dataSpecialItem.contactName))])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("Email:")]), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.dataSpecialItem.contactEmail))])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("SĐT:")]), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.dataSpecialItem.contactPhone))])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('div', [_vm._v("Thời gian sử dụng:")]), _c('div', {
    staticClass: "d-flex flex-column fw-700 align-items-end"
  }, [_c('div', {
    staticClass: "text-right text-warning"
  }, [_vm._v(" Trước giờ khởi hành chuyến bay ")])])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("Thời gian checkin:")]), _c('span', {
    staticClass: "fw-700",
    class: _vm.dataSpecialItem.checkinAt && ['CHECKIN'].includes(_vm.dataSpecialItem.status) ? 'text-success' : 'text-danger'
  }, [_vm._v(" " + _vm._s(_vm.dataSpecialItem.checkinAt && ['CHECKIN'].includes(_vm.dataSpecialItem.status) ? _vm.convertISODateTime(_vm.dataSpecialItem.checkinAt).dateTime : 'Chưa checkin') + " ")])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('div', [_vm._v("Sân bay:")]), _c('div', {
    staticClass: "d-flex flex-column fw-700 align-items-end"
  }, [_c('div', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.dataSpecialItem.airport.name) + " ")])])]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-baseline mb-50"
  }, [_c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" Phòng chờ lựa chọn: ")]), _c('div', {
    staticClass: "d-flex flex-column fw-700 align-items-end"
  }, [_c('div', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.dataSpecialItem.airportLounge.name) + " ")]), _c('span', [_vm._v("(" + _vm._s(_vm.dataSpecialItem.airportLounge.isDomestic ? 'Nội địa' : 'Quốc tế') + ")")])])]), _c('div', [_vm._v("Loại vé:")]), _c('div', {
    staticClass: "d-flex flex-column ml-1 fw-700 mb-50"
  }, _vm._l(_vm.dataSpecialItem.bookingFares, function (fareClass, indexFare) {
    return _c('div', {
      key: indexFare,
      staticClass: "d-flex-between font-weight-bold"
    }, [_c('span', [_vm._v(_vm._s(fareClass.airportLoungeFare.name))]), _c('span', [_vm._v("x" + _vm._s(fareClass.count))])]);
  }), 0)]), _c('b-card', [_c('div', {
    staticClass: "fw-700 mb-50"
  }, [_vm._v(" Thông tin booking: ")]), _c('div', {
    staticClass: "ml-1"
  }, [_c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("Mã PNR")]), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.dataSpecialItem.flightCode))])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("Hành trình")]), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.dataSpecialItem.flightTrip.split(' ').join('-')))])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("Ngày bay")]), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.dataSpecialItem.flightTime).dateTime))])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("Số hiệu chuyến bay")]), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.dataSpecialItem.flightNumber.split(' ').join('-')))])]), _c('div', {
    staticClass: "d-flex-between mb-50"
  }, [_c('span', [_vm._v("Remark (Thông tin đại lý)")]), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.dataSpecialItem.flightRemark))])])])])], 1), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center mt-2 mt-lg-0",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "fw-800 mb-50"
  }, [_vm._v(" Mã QR ")]), _c('div', {
    staticClass: "fw-800 font-medium-1"
  }, [_vm._v(" BookingCode: "), _c('span', {
    staticClass: "text-airline"
  }, [_vm._v("ALB" + _vm._s(_vm.dataSpecialItem.id))])]), _c('b-img', {
    style: "".concat(['lg', 'xl'].includes(_vm.appBreakPoint) ? 'width: 300px' : 'width: 300px', ";"),
    attrs: {
      "src": _vm.qrCodeUrl
    }
  }), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-button', {
    staticClass: "rounded-lg d-flex-center",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadImage(_vm.dataSpecialItem);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DownloadIcon"
    }
  }), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("Tải về máy")])], 1)], 1)], 1)], 1) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }