<template>
  <div>
    <b-modal
      id="modal-modify-special-services-qr-codes"
      title="Thông tin QR Code"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
      @hide="hideHandle"
    >
      <template #modal-footer="{ close }">
        <div class="w-100 d-flex-center">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            size="md"
            variant="outline-secondary"
            class="center rounded-pill"
            @click="close()"
          >
            {{ $t('reservation.back') }}
          </b-button>
        </div>
      </template>

      <div>
        <b-row v-if="dataSpecialItem">
          <b-col
            cols="12"
            lg="6"
          >
            <b-card>
              <div class="d-flex-between mb-50">
                <span>Họ và tên người mua:</span>
                <span class="fw-700">{{ dataSpecialItem.contactName }}</span>
              </div>
              <div class="d-flex-between mb-50">
                <span>Email:</span>
                <span class="fw-700">{{ dataSpecialItem.contactEmail }}</span>
              </div>
              <div class="d-flex-between mb-50">
                <span>SĐT:</span>
                <span class="fw-700">{{ dataSpecialItem.contactPhone }}</span>
              </div>
              <div class="d-flex-between mb-50">
                <div>Thời gian sử dụng:</div>
                <div class="d-flex flex-column fw-700 align-items-end">
                  <div class="text-right text-warning">
                    Trước giờ khởi hành chuyến bay
                  </div>
                </div>
              </div>
              <div class="d-flex-between mb-50">
                <span>Thời gian checkin:</span>
                <span
                  class="fw-700"
                  :class="dataSpecialItem.checkinAt && ['CHECKIN'].includes(dataSpecialItem.status) ? 'text-success' : 'text-danger'"
                >
                  {{
                    dataSpecialItem.checkinAt && ['CHECKIN'].includes(dataSpecialItem.status)
                      ? convertISODateTime(dataSpecialItem.checkinAt).dateTime
                      : 'Chưa checkin'
                  }}
                </span>
              </div>

              <div class="d-flex-between mb-50">
                <div>Sân bay:</div>
                <div class="d-flex flex-column fw-700 align-items-end">
                  <div class="text-right">
                    {{ dataSpecialItem.airport.name }}
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-baseline mb-50">
                <div class="text-nowrap">
                  Phòng chờ lựa chọn:
                </div>
                <div class="d-flex flex-column fw-700 align-items-end">
                  <div class="text-right">
                    {{ dataSpecialItem.airportLounge.name }}
                  </div>
                  <span>({{ dataSpecialItem.airportLounge.isDomestic ? 'Nội địa' : 'Quốc tế' }})</span>
                </div>
              </div>

              <div>Loại vé:</div>
              <div class="d-flex flex-column ml-1 fw-700 mb-50">
                <div
                  v-for="(fareClass, indexFare) of dataSpecialItem.bookingFares"
                  :key="indexFare"
                  class="d-flex-between font-weight-bold"
                >
                  <span>{{ fareClass.airportLoungeFare.name }}</span>
                  <span>x{{ fareClass.count }}</span>
                </div>
              </div>

              <!-- <div class="d-flex-between mt-1">
                <span>Đại lý OTA:</span>
                <span class="fw-700">Bảo Gia Trần</span>
              </div> -->
            </b-card>

            <b-card>
              <div class="fw-700 mb-50">
                Thông tin booking:
              </div>
              <div class="ml-1">
                <div class="d-flex-between mb-50">
                  <span>Mã PNR</span>
                  <span class="fw-700">{{ dataSpecialItem.flightCode }}</span>
                </div>
                <div class="d-flex-between mb-50">
                  <span>Hành trình</span>
                  <span class="fw-700">{{ dataSpecialItem.flightTrip.split(' ').join('-') }}</span>
                </div>
                <div class="d-flex-between mb-50">
                  <span>Ngày bay</span>
                  <span class="fw-700">{{ convertISODateTime(dataSpecialItem.flightTime).dateTime }}</span>
                </div>
                <div class="d-flex-between mb-50">
                  <span>Số hiệu chuyến bay</span>
                  <span class="fw-700">{{ dataSpecialItem.flightNumber.split(' ').join('-') }}</span>
                </div>
                <div class="d-flex-between mb-50">
                  <span>Remark (Thông tin đại lý)</span>
                  <span class="fw-700">{{ dataSpecialItem.flightRemark }}</span>
                </div>
              </div>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            lg="6"
            class="d-flex flex-column align-items-center mt-2 mt-lg-0"
          >
            <div class="fw-800 mb-50">
              Mã QR
            </div>
            <div class="fw-800 font-medium-1">
              BookingCode: <span class="text-airline">ALB{{ dataSpecialItem.id }}</span>
            </div>
            <b-img
              :src="qrCodeUrl"
              :style="`${
                ['lg', 'xl'].includes(appBreakPoint)
                  ? 'width: 300px'
                  : 'width: 300px'
              };`"
            />
            <div class="mt-1">
              <b-button
                variant="outline-info"
                class="rounded-lg d-flex-center"
                @click="downloadImage(dataSpecialItem)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="ml-1">Tải về máy</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BModal, BButton, BCard, BRow, BCol, BImg,
} from 'bootstrap-vue'
import { ref, toRefs } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import {
  convertISODateTime,
} from '@/@core/utils/filter'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BImg,
    BRow,
    BCol,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
    dataSpecialItem: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props) {
    const { dataBooking, dataSpecialItem } = toRefs(props)
    // const {
    //   getBookingData,
    // } = useReservationHandle()

    const qrCodeUrl = ref(null)

    function showHandle() {
      qrCodeUrl.value = dataSpecialItem.value?.qrCode
    }

    function hideHandle() {
      qrCodeUrl.value = null
    }

    function downloadImage(data) {
      const link = document.createElement('a')
      link.href = data.qrCode
      link.download = `QRCode_${data.contactName.split(' ').join('_')}_ALB${data.id}.jpg`
      link.click()
    }

    return {
      dataBooking,
      qrCodeUrl,
      convertISODateTime,
      showHandle,
      hideHandle,
      isEmpty,

      downloadImage,
    }
  },
}
</script>
